<!--Theme customizer Starts-->
<div class="customizer border-left-blue-grey border-left-lighten-4 d-none d-sm-none d-md-block">
	<a class="customizer-close">
		<i class="ft-x font-medium-3"></i>
	</a>
	<div class="customizer-content p-3 ps-container ps-theme-dark text-left" data-ps-id="df6a5ce4-a175-9172-4402-dabd98fc9c0a">
		<h4 class="text-uppercase mb-0 text-bold-400">Theme Customizer</h4>
		<hr>

		<!--Sidebar Options Starts-->
		<h6 class="text-center text-bold-500 mb-3 text-uppercase">Sidebar Color Options</h6>
		<div class="cz-bg-color">
			<div class="row p-1">
				<div class="col">
					<span class="gradient-pomegranate d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="pomegranate"></span>
				</div>
				<div class="col">
					<span class="gradient-king-yna d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="king-yna"></span>
				</div>
				<div class="col">
					<span class="gradient-ibiza-sunset d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="ibiza-sunset"></span>
				</div>
				<div class="col">
					<span class="gradient-flickr d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="flickr"></span>
				</div>
				<div class="col">
					<span class="gradient-purple-bliss d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="purple-bliss"></span>
				</div>
				<div class="col">
					<span class="gradient-man-of-steel d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="man-of-steel"></span>
				</div>
				<div class="col">
					<span class="gradient-purple-love d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="purple-love"></span>
				</div>
			</div>
			<div class="row p-1">
				<div class="col">
					<span class="bg-black d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="black"></span>
				</div>
				<div class="col">
					<span class="bg-grey d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="white"></span>
				</div>
				<div class="col">
					<span class="bg-primary d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="primary"></span>
				</div>
				<div class="col">
					<span class="bg-success d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="success"></span>
				</div>
				<div class="col">
					<span class="bg-warning d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="warning"></span>
				</div>
				<div class="col">
					<span class="bg-info d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="info"></span>
				</div>
				<div class="col">
					<span class="bg-danger d-block rounded-circle" style="width:20px; height:20px;" data-bg-color="danger"></span>
				</div>
			</div>
		</div>
		<!--Sidebar Options Ends-->
		<hr>

		<!--Sidebar BG Image Starts-->
		<h6 class="text-center text-bold-500 mb-3 text-uppercase">Sidebar Bg Image</h6>
		<div class="cz-bg-image row">
			<div class="col mb-3">
				<img src="assets/img/sidebar-bg/01.jpg" class="rounded" width="90">
			</div>
			<div class="col mb-3">
				<img src="assets/img/sidebar-bg/02.jpg" class="rounded" width="90">
			</div>
			<div class="col mb-3">
				<img src="assets/img/sidebar-bg/03.jpg" class="rounded" width="90">
			</div>
			<div class="col mb-3">
				<img src="assets/img/sidebar-bg/04.jpg" class="rounded" width="90">
			</div>
			<div class="col mb-3">
				<img src="assets/img/sidebar-bg/05.jpg" class="rounded" width="90">
			</div>
			<div class="col mb-3">
				<img src="assets/img/sidebar-bg/06.jpg" class="rounded" width="90">
			</div>
		</div>
		<!--Sidebar BG Image Ends-->
		<hr>

		<!--Sidebar BG Image Toggle Starts-->
		<div class="togglebutton">
			<div>
				<span>Sidebar Bg Image</span>
				<div class="float-right">
					<div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
						<input type="checkbox" class="custom-control-input cz-bg-image-display" checked id="sidebar-bg-img">
						<label class="custom-control-label" for="sidebar-bg-img"></label>
					</div>
				</div>
			</div>
		</div>
		<!--Sidebar BG Image Toggle Ends-->
		<hr>

		<!--Compact Menu Starts-->
		<div class="togglebutton">
			<div>
				<span>Compact Menu</span>
				<div class="float-right">
					<div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
						<input type="checkbox" class="custom-control-input cz-compact-menu" id="cz-compact-menu">
						<label class="custom-control-label" for="cz-compact-menu"></label>
					</div>
				</div>
			</div>
		</div>
		<!--Compact Menu Ends-->
		<hr>

		<!--RTL Starts-->
		<div class="togglebutton">
			<div>
				<span>RTL</span>
				<div class="float-right">
					<div class="custom-control custom-checkbox mb-2 mr-sm-2 mb-sm-0">
						<input type="checkbox" [checked] ="options.direction == 'rtl' ? 'checked' : false" class="custom-control-input cz-rtl" id="cz-rtl" (change)="options.direction = (options.direction == 'rtl' ? 'ltr' : 'rtl'); sendOptions()">
						<label class="custom-control-label" for="cz-rtl"></label>
					</div>
				</div>
			</div>
		</div>
		<!--RTL Ends-->
		<hr>

		<!--Sidebar Width Starts-->
		<div>
			<label for="cz-sidebar-width">Sidebar Width</label>
			<select id="cz-sidebar-width" class="custom-select cz-sidebar-width float-right">
				<option value="small">Small</option>
				<option value="medium" selected>Medium</option>
				<option value="large">Large</option>
			</select>
		</div>
		<!--Sidebar Width Ends-->
	</div>
</div>
<!--Theme customizer Ends-->
