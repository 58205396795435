import {Injectable} from '@angular/core';
import {AuthService} from './shared/auth/auth.service';
import {Restangular} from 'ngx-restangular';
import {Router} from '@angular/router';
import {StorageType} from 'angular-persistence';
import {Breadcrumb} from './shared/models/custom-breadcrumb.model';

@Injectable()
export class AppService {

  interestTypes: any[] = [{value: 'simple_interest', name: 'Simple Interest'}, {value: 'compound_interest', name: 'Compound Interest'}];
  paymentTypes: any[] = ['CASH', 'PAYPAL', 'BANK_TRANSFER', 'CREDIT_NOTE', 'SALES_INVOICE', 'EBAY_REFUND', 'SHOPIFY_REFUND', 'AMAZON_REFUND','TIKTOK_REFUND'];//"EBAY", "SHOPIFY"
  invoiceStatusList: string[] = ['PENDING', 'PROCESSING', 'BOOKED', 'PROCESSED', 'CANCELED', 'READY_FOR_DELIVERY'];
  chequeStatusTypes: any[] = [{value: 'collected', name: 'Collected'}, {value: 'send_to_bank', name: 'Send To Bank'},
    {value: 'realized', name: 'Realized'}, {value: 'returned', name: 'Returned'}, {value: 'issued', name: 'Issued'}];
  currencies: any[] = ['GBP', 'EUR', 'USD'];
  unitOfMeasures = [
    {'name': 'Unit'},
    {'name': 'Kilogram (Kg)'},
    {'name': 'Pound (lb)'},
    {'name': 'Liter (l)'},
    {'name': 'Meters (m)'},
    {'name': 'Inches'},
    {'name': 'Feet'},
    {'name': 'Gallon'},
    {'name': 'Ounce (oz)'}
  ];
  breadcrumbs: Breadcrumb[] = [];
  public defaultPersistentStorage: StorageType = StorageType.SESSION;

  constructor(private authService: AuthService, private restangular: Restangular, private router: Router) {
  }

  public mainMenu() {
    this.breadcrumbs = [];
  }

  public setLoaderValue(value) {
    if (value) {
      $('#loader').show();
    } else {
      $('#loader').hide();
    }
  };

  public validateForm(form): boolean {
    Object.keys(form.controls).forEach(field => { // {1}
      const control = form.get(field);            // {2}
      control.markAsTouched({onlySelf: true});       // {3}
    });
    return form.valid;
  }

  public dateToString(date) {
    date = new Date(date);
    date = date.getFullYear() + '-' + this.leftPad((date.getMonth() + 1), 2) + '-' + this.leftPad(date.getDate(), 2);
    return date;
  }

  public getAccessToken() {
    let bearerToken = this.authService.getToken();
    return 'Bearer ' + bearerToken;
  }

  public authUser() {
    return this.authService.getAuthUser();
  }

  public login() {
    return this.authService.login();
  }

  public removeFileFromServer(fileName) {
    return this.restangular.one('upload').customGET('removeFile', {fileName: fileName});
  }

  public leftPad(number, targetLength) {
    let output = number + '';
    while (output.length < targetLength) {
      output = '0' + output;
    }
    return output;
  }

}

export function downloadFile(fileName: string, response) {
  let url = (window.URL).createObjectURL(response);
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);//required in FF, optional for Chrome
  anchor.download = fileName;
  anchor.href = url;
  anchor.click();
}


export function downloadFileFromUrl(fileName: string, url) {
  let anchor = document.createElement('a');
  document.body.appendChild(anchor);//required in FF, optional for Chrome
  anchor.download = fileName;
  anchor.href = url;
  anchor.target = '_blank';
  anchor.click();
}

export function UrlToDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
