declare var envDevConfig: any;
declare var envLocalConfig: any;
declare var envConfig: any;

const config = new envConfig();

export const environment = {
  production: config.isProduction(),
  baseUrl: config.getBaseUrl(),
  imgUrl: config.getImageUrl(),
  vyperEdiUrl: config.getVyperEdiUrl(),
  vyperBrandPortalUrl: config.getVyperBrandPortalUrl(),
  vyperRefurbishPortalUrl: config.getVyperRefurbishPortalUrl(),
  purchaseInvoiceSettlementNotifyEmails: config.getPurchaseInvoiceSettlementNotifyEmails(),
  keycloakAuth : {
    config: {
      url: config.getKeycloakUrl(),
      realm: config.getKeycloakRealm(),
      clientId: config.getKeycloakClient(),
    },
    initOptions: {
      onLoad: config.getKeycloakOnLoad(),
      checkLoginIframe: config.isKeycloakCheckLoginFrame()
    },
    enableBearerInterceptor: config.isKeycloakEnableBearerInterceptor(),
    bearerExcludedUrls: config.getKeycloakExcludedUrls(),
  }
};
