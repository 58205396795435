import { Routes, RouterModule } from '@angular/router';
import {KeycloakGuard} from "../auth/keycloak-guard.service";

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [

  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'pages',
    loadChildren: './pages/full-pages/full-pages.module#FullPagesModule'
  },
  {
    path: 'usermanagement',
    loadChildren: './user-management/user-management.module#UserManagementModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'product',
    loadChildren: './product/product.module#ProductModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'warehouse',
    loadChildren: './warehouse/warehouse.module#WarehouseModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'cost-center',
    loadChildren: './cost-center/cost-center.module#CostCenterModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'currency',
    loadChildren: './currency/currency.module#CurrencyModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'person',
    loadChildren: './person/person.module#PersonModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'purchase-invoice',
    loadChildren: './purchase-invoice/purchase-invoice.module#PurchaseInvoiceModule', canActivate: [KeycloakGuard]
  },

  {
    path: 'purchase-invoice-settlement',
    loadChildren: './purchase-invoice-settlement/purchase-invoice-settlement.module#PurchaseInvoiceSettlementModule', canActivate: [KeycloakGuard]
  },

  {
    path: 'purchase-order',
    loadChildren: './purchase-order/purchase-order.module#PurchaseOrderModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'good-received-note',
    loadChildren: './good-received-note/good-received-note.module#GoodReceivedNoteModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'sales-invoice',
    loadChildren: './sales-invoice/sales-invoice.module#SalesInvoiceModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'return-ticket',
    loadChildren: './return-ticket/return-ticket.module#ReturnTicketModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'credit-note',
    loadChildren: './credit-note/credit-note.module#CreditNoteModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'inventory',
    loadChildren: './inventory/inventory.module#InventoryModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'customer',
    loadChildren: './customer/customer.module#CustomerModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'api',
    loadChildren: './api/api.module#ApiModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'configuration',
    loadChildren: './configuration/configuration.module#ConfigurationModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'reports',
    loadChildren: './reports/reports.module#ReportsModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'inventory-transfer',
    loadChildren: './inventory-transfer/inventory-transfer.module#InventoryTransferModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'excel-mapping',
    loadChildren: './excel-mapping/excel-mapping.module#ExcelMappingModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'rma',
    loadChildren: './rma/rma.module#RmaModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'manual-stock',
    loadChildren: './manual-stock/manual-stock.module#ManualStockModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'draft',
    loadChildren: './draft-process/draft-process.module#DraftProcessModule', canActivate: [KeycloakGuard]
  },
  {
    path: 'shipping-carrier',
    loadChildren: './shipping-carrier/shipping-carrier.module#ShippingCarrierModule', canActivate: [KeycloakGuard]
  },
];
