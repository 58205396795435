import {ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, Route} from '@angular/router';
import { Injectable } from '@angular/core';
import {NgxPermissionsGuard, NgxPermissionsService, NgxRolesService} from 'ngx-permissions';
import {PreviousRouteService} from '../routes/previous-route.service';
import {ToastrService} from 'ngx-toastr';

@Injectable()
export class PermissionGuard extends NgxPermissionsGuard {

  private routerPrivate: Router;

  private prevUrl: string;

  constructor(permissionsService: NgxPermissionsService, rolesService: NgxRolesService, router: Router,
              private previousRouteService: PreviousRouteService, private toastr: ToastrService, private activedRoute: ActivatedRoute) {
    super(permissionsService, rolesService, router);
    this.routerPrivate = router;
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve, reject) => {

      console.log(route, state);
      const isAllowed = await super.canActivate(route, state);
      // resolve(isAllowed);
      if (isAllowed) {
        resolve(isAllowed);
      } else {
        this.prevUrl = this.previousRouteService.getCurrentUrl();
        if (!this.prevUrl || this.prevUrl == '' || this.prevUrl == '/') {
          this.routerPrivate.navigate(['dashboard/dashboard1'], { relativeTo: this.activedRoute.parent });
        }
        this.toastr.error( 'You are not authorised', 'Restricted!', { closeButton: true , timeOut: 4000 });
      }
      resolve(isAllowed);
    });
  }

}
