import { Injectable } from '@angular/core';
import {InitializerService} from "./initializer.service";

@Injectable()
export class AuthService {

  constructor(private initializerService:InitializerService) {}

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email?: string, password?: string) {
    //your code for checking credentials and getting tokens for for signing in user
    this.initializerService.login();
  }

  logout() {
    return this.initializerService.logout();
  }

  getToken() {
    return this.initializerService.getToken();
  }

  refreshToken(): Promise<boolean> {
    return this.initializerService.updateToken();
  }

  isTokenExpired() {
    return this.initializerService.isTokenExpired();
  }

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    return this.initializerService.isAuthenticated();
  }

  getAuthUser(){
    return this.initializerService.getAuthUser();
  }

  login() {
    return this.initializerService.login();
    // return this.ngaaUserService.signout();
  }
}
